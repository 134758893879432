// import store from '@/store'

// const { pedidos } = store.state.pedidos
// const pendientes = pedidos.filter(p => p.status === 0)

export default [
  {
    header: 'Bimbo',
  },
  {
    title: 'Shop Menu',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'Shop',
        route: 'bimbo-shop',
      },
      {
        title: 'List',
        route: 'bimbo-shop-list',
      },
    ],
  },
  {
    title: 'Rider',
    icon: 'PackageIcon',
    route: 'bimbo-rider',
  },
  {
    title: 'Orders',
    icon: 'FilterIcon',
    // tag: `${pedidos.length}`,
    children: [
      {
        title: 'Pending',
        icon: 'ArrowDownRightIcon',
        // tag: `${pendientes.length}`,
        route: 'bimbo-pedidos-pending',
      },
      {
        title: 'Picked',
        icon: 'ArrowRightIcon',
        route: 'bimbo-pedidos-picked',
      },
      {
        title: 'Routed',
        icon: 'ArrowDownIcon',
        route: 'bimbo-pedidos-routed',
      },
      {
        title: 'Dispatched',
        icon: 'ArrowDownLeftIcon',
        route: 'bimbo-pedidos-dispatched',
      },
      {
        title: 'Delivered',
        icon: 'CheckIcon',
        route: 'bimbo-pedidos-delivered',
      },
    ],
  },
  {
    title: 'Picking',
    icon: 'PackageIcon',
    tag: 'nuevo',
    tagVariant: 'light-success',
    route: 'bimbo-pedidos-picking-v2',
  },
  // {
  //   title: 'PedidosOld',
  //   icon: 'ShoppingCartIcon',
  //   children: [
  //     {
  //       title: 'List',
  //       icon: 'CircleIcon',
  //       route: 'bimbo-pedidos-list',
  //     },
  //     {
  //       title: 'Picking',
  //       icon: 'CircleIcon',
  //       route: 'bimbo-pedidos-picking',
  //     },
  //   ],
  // },
  {
    title: 'Invoice',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'List',
        route: 'bimbo-pedidos-list',
      },
    ],
  },
  {
    title: 'Clientes',
    icon: 'UsersIcon',
    route: 'bimbo-clientes-list',
  },
  {
    title: 'Productos',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'List',
        route: 'bimbo-productos-list',
      },
      {
        title: 'Inventory',
        route: 'bimbo-inventarios-list',
      },
      {
        title: 'Orders Input',
        route: 'bimbo-entradas-list',
      },
    ],
  },
  {
    title: 'Warehouse',
    icon: 'ClipboardIcon',
    disabled: true,
    route: null,
  },
  {
    header: 'Config',
  },
  {
    title: 'Global',
    icon: 'SettingsIcon',
    route: null,
  },
  // {
  //   title: 'Test',
  //   icon: 'AlertTriangleIcon',
  //   route: { name: 'bimbo-pedidos-ticket', params: { id: '1618903789456C1606848R5694' } },
  // },
]
