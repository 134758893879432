import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import {
  reducer,
  fechaHoraCortas,
} from '@/libs/utils-pedidos'
import { useRouter } from '@core/utils/utils'
// import { useRouter } from 'vue-router'

export const useShopFiltersSortingAndPagination = () => {
  const filters = ref({
    q: '',
    priceRangeDefined: 'all',
    priceRange: [0, 100],
    categories: [],
    brands: [],
    ratings: null,
    page: 1,
    perPage: 9,
  })

  const filterOptions = {
    priceRangeDefined: [
      { text: 'All', value: 'all' },
      { text: '<= $10', value: '<=10' },
      { text: '$10 - $100', value: '10-100' },
      { text: '$100 - $500', value: '100-500' },
      { text: '>= $500', value: '>=500' },
    ],
    categories: [
      'Appliances',
      'Audio',
      'Cameras & Camcorders',
      'Car Electronics & GPS',
      'Cell Phones',
      'Computers & Tablets',
      'Health, Fitness & Beauty',
      'Office & School Supplies',
      'TV & Home Theater',
      'Video Games',
    ],
    brands: ['Insignia™', 'Samsung', 'Metra', 'HP', 'Apple', 'GE', 'Sony', 'Incipio', 'KitchenAid', 'Whirlpool'],
    ratings: [
      { rating: 4, count: 160 },
      { rating: 3, count: 176 },
      { rating: 2, count: 291 },
      { rating: 1, count: 190 },
    ],
  }

  // Sorting
  const sortBy = ref({ text: 'Featured', value: 'featured' })
  const sortByOptions = [
    { text: 'Featured', value: 'featured' },
    { text: 'Lowest', value: 'price-asc' },
    { text: 'Highest', value: 'price-desc' },
  ]

  return {
    // Filter
    filters,
    filterOptions,

    // Sort
    sortBy,
    sortByOptions,
  }
}

export const useShopUi = () => {
  const processShop = computed(() => store.state.ecommerce.processShop)
  const ProcessVolver = () => store.commit('ecommerce/SET_PROCESS', processShop.value - 1)

  const itemView = 'grid-view'
  const itemViewOptions = [
    { icon: 'GridIcon', value: 'grid-view' },
    { icon: 'ListIcon', value: 'list-view' },
  ]

  // Pagination count <= required by pagination component
  const totalProducts = ref(null)

  return {
    itemView,
    itemViewOptions,
    totalProducts,
    processShop,
    ProcessVolver,
  }
}

export const useShopRemoteData = () => {
  // const { router } = useRouter()
  const fetchProducts = () => store.dispatch('productos/getProductosOnce')
  const products = computed(() => {
    // const { items } = useCartItems()
    const items = computed(() => store.state.ecommerce.cartItems)
    const { productos } = store.state.productos
    const result = []
    productos.forEach(element => {
      const producto = element
      const img = producto.pictures.filter(i => i.isPrimary)[0]
      producto.image = img ? img.link : null
      producto.rating = producto.ranking || 5
      const isInCart = items.value.filter(i => i.id === producto.id)[0]
      producto.isInCart = !!isInCart
      result.push(producto)
    })
    return result
  })

  const fetchClients = () => store.dispatch('clientes/getClientesOnce')
  const clientsOrigins = computed(() => store.state.clientes.clientes)

  const clients = computed(() => clientsOrigins.value.map(c => ({ key: c.id, title: `${c.id} ${c.nombre}` })))

  const clientSelected = ref(null)

  const clientSelectedData = computed(() => {
    if (!clientSelected.value) return null
    return clientsOrigins.value.filter(i => i.id === clientSelected.value.key)[0]
  })

  const saveSelectClient = () => store.dispatch('ecommerce/selectClient', clientSelectedData.value)

  const checkClientSelected = computed(() => store.state.ecommerce.cliente)

  const resultClient = () => {
    const cli = clientSelectedData.value || checkClientSelected.value
    if (cli !== null) clientSelected.value = cli.id
  }

  // const calcBultos = productsCart => {
  //   if (!productsCart.length) return 0
  //   const reducer = (accumulator, currentValue) => accumulator + currentValue
  //   return productsCart.map(p => p.uds).reduce(reducer)
  // }

  const agente = computed(() => store.state.auth.dataUser)

  const sendPedido = () => {
    // console.log('enviar Pedido')
    const items = computed(() => store.getters['ecommerce/cartItemsSort'])
    const productos = items.value.map(p => ({
      ref: p.id,
      uds: p.uds,
    }))

    // const totalBultos = calcBultos(productos)
    // console.log(productos)
    // console.log(totalBultos)
    // console.log(checkClientSelected.value)
    const payload = {
      cliente: checkClientSelected.value,
      productos,
      agente: agente.value,
    }
    // console.log(payload)
    store.dispatch('pedidos/addPedido', payload)
    store.dispatch('ecommerce/resetData')
    // router.replace({ name: 'bimbo-shop' })
    // addPedido
  }

  const fetchOrders = async () => {
    const { id } = agente.value
    // const id = 'UJ35cVVqkCWGO30Df9dq5wBW6Cx2'
    // console.log(id)
    const result = await store.dispatch('pedidos/getPedidoByAgent', id)
    const pedidosResult = result.map(pedido => {
      const ped = pedido
      ped.bultos = ped.productos.map(p => p.uds).reduce(reducer)
      ped.fechaIso = fechaHoraCortas(ped.fecha)
      ped.fechaEntrega = ped.delivered_at ? fechaHoraCortas(ped.delivered_at) : ''
      return ped
    })
    return pedidosResult
  }

  /**
   * Tablas
   */
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = ref([10, 25, 50, 100, 500, 1000, 1500, 2000])
  const tableColumns = [
    { key: 'client', label: 'Cliente', sortable: false },
    { key: 'pedido', label: 'Pedido', sortable: false },
    // { key: 'cliente_ruta', label: 'Z', sortable: false },
    // { key: 'bultos', label: 'B', sortable: false },
    { key: 'status', label: 'Status', sortable: false },
    // { key: 'actions' },
  ]

  const splitedPedido = pedidoId => pedidoId.split('R')[1]

  const resolveUserStatusVariant = status => {
    const result = {
      txt: 'Unknown',
      color: 'danger',
    }
    switch (status) {
      case 0:
        result.txt = 'Pending'
        result.color = 'secondary'
        break
      case 1:
        result.txt = 'Picked'
        result.color = 'primary'
        break
      case 3:
        result.txt = 'Routed'
        result.color = 'warning'
        break
      case 7:
        result.txt = 'Dispatched'
        result.color = 'info'
        break
      case 15:
        result.txt = 'Delivered'
        result.color = 'success'
        break
      default:
        break
    }
    return result
  }

  return {
    products,
    fetchProducts,
    fetchClients,
    clients,
    clientSelected,
    clientSelectedData,
    saveSelectClient,
    checkClientSelected,
    resultClient,
    sendPedido,
    agente,
    fetchOrders,
    // Tabla
    sortBy,
    isSortDirDesc,
    perPage,
    currentPage,
    perPageOptions,
    tableColumns,
    splitedPedido,
    resolveUserStatusVariant,
  }
}

export const useCartItems = () => {
  const { router } = useRouter()
  // const items = computed(() => store.state.ecommerce.cartItems)
  const items = computed(() => store.getters['ecommerce/cartItemsSort'])
  const fetchCartItems = () => store.dispatch('ecommerce/getCartItems')

  // bimbo-e-commerce-checkout
  const addItems = pr => {
    const { products } = useShopRemoteData()
    const selected = products.value.filter(p => p.id === pr.id)[0]
    if (selected && selected.isInCart) {
      router.push({ name: 'bimbo-e-commerce-checkout' })
    } else {
      store.dispatch('ecommerce/addCartItem', pr)
    }
  }

  const totalAmount = computed(() => {
    let result = 0
    items.value.forEach(item => {
      result += (item.price * item.uds)
    })
    return result.toLocaleString()
  })

  const removeItemFromCart = productId => store.dispatch('ecommerce/removeProductFromCart', productId)

  const resetCart = () => store.dispatch('ecommerce/resetData')

  const initializeShop = () => {
    resetCart()
    store.commit('ecommerce/SET_PROCESS', 1)
  }

  const changeUdsItem = item => {
    // console.log(item)
    store.dispatch('ecommerce/changeUdsCartItem', item)
    // store.commit('ecommerce/CHANGE_USD_ITEMS', item)
  }

  const checkResetCart = () => {
    // Chequea si debe resetear todo
    const { checkClientSelected } = useShopRemoteData()
    if (checkClientSelected.value) {
      // console.log('Hay un cliente configurado...')
      return
    }
    resetCart()
  }

  return {
    fetchCartItems,
    items,
    totalAmount,
    addItems,
    removeItemFromCart,
    checkResetCart,
    initializeShop,
    changeUdsItem,
  }
}
