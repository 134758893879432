<template>
  <layout-vertical>

    <router-view />

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />

    <!-- <custom-nav-bar
      v-if="false"
      slot="navbar"
    /> -->
    <!-- :toggle-vertical-menu-active="toggleVerticalMenuActive" -->

    <div slot="footer">
      <p class="mb-0">
        &copy; CargoBici {{ new Date().getFullYear() }}
      </p>
    </div>

  </layout-vertical>
</template>

<script>
import LayoutVertical from '@/layouts/corelayouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@/layouts/corelayouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
// import { onUnmounted } from '@vue/composition-api'
// import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import useAppConfig from '@core/app-config/useAppConfig'
// import CustomNavBar from './AppNavbarVerticalLayout.vue'
// import useVerticalLayout from './useVerticalLayout'

export default {
  components: {
    // CustomNavBar,
    AppCustomizer,
    LayoutVertical,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
